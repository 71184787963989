























































import { Component, Vue } from 'vue-property-decorator';
import { readDistritos } from '@/store/distrito/getters';
import { dispatchDeleteDistrito, dispatchGetDistritos } from '@/store/distrito/actions';
import { IDistrito } from '@/interfaces/distrito';
import ModalConfirmacao from '@/components/ModalConfirmacao.vue';
import ModalDetalhesDistrito from '@/components/ModalDetalhesDistrito.vue';

@Component({
  components: {
    ModalConfirmacao,
    ModalDetalhesDistrito,
  },
})
export default class ListarDistritos extends Vue {
  public modalExclusaoAtivo: boolean = false;
  public modalDetalhesDistritoAtivo: boolean = false;
  public distritoExclusao;
  public distritoDetalhes;

  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'center',
    },
    {
      text: 'Nome',
      sortable: true,
      value: 'nome',
      align: 'center',
    },
    {
      text: 'Ações',
      align: 'center',
    },
  ];

  public options = {
    rowsPerPage: 10,
  };

  get distritos() {
    return readDistritos(this.$store);
  }

  public abrirModalExclusao(distrito: IDistrito) {
    this.distritoExclusao = distrito;
    this.modalExclusaoAtivo = true;
  }

  public async confirmarExclusao() {
    const excluiu = await dispatchDeleteDistrito(this.$store, this.distritoExclusao);
    this.modalExclusaoAtivo = false;
  }

  public abrirModalDetalhesDistrito(distrito: IDistrito) {
    this.distritoDetalhes = distrito;
    this.modalDetalhesDistritoAtivo = true;
  }

  public async mounted() {
    await dispatchGetDistritos(this.$store);
  }
}
