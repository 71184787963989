































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  @Prop(Object) public distrito: object | undefined;
  public exibir: boolean = true;

  @Watch('exibir')
  public onExibirChanged(val: boolean, oldVal: boolean) {
    if (!val) {
      this.fecharModal();
    }
  }

  @Emit('modalClose')
  public fecharModal() {
    return;
  }

  @Emit('modalConfirm')
  public confirmar() {
    return;
  }
}
